import type { Ref } from 'vue';
import { computed } from 'vue';
import type { InputSize, InputVariant } from '@/components/Input/types';
import { simplifyTailwindClasses } from '@/utils/simplifyTailwindClasses';

// LABEL
const defaultLabelClasses =
  'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300';

// INPUT
const defaultInputClasses =
  'font-simplerLight text-sm block w-full rounded dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white';
const disabledInputClasses = 'cursor-not-allowed bg-gray-100';
const inputSizeClasses: Record<InputSize, string> = {
  lg: 'p-4',
  md: 'p-2.5 text-sm',
  sm: 'p-1 text-sm',
};

const inputVariantClasses: Record<InputVariant, string> = {
  none: 'bg-transparent',
  default:
    'bg-white border-1 has-[:focus]:border-primary text-gray-900 text-sm rounded-[4px]',
  task: 'rounded-3xl bg-secondary-75 text-sm font-simplerLight text-secondary-600 border-0',
  post: 'rounded-lg bg-[#F2F6F9] text-sm font-simplerLight text-secondary-600 border-0',
};

export type UseInputClassesProps = {
  size: Ref<InputSize>;
  variant: Ref<InputVariant>;
  disabled: Ref<boolean>;
  error: Ref<boolean>;
};

export function useInputClasses(props: UseInputClassesProps | any): {
  inputClasses: Ref<string>;
  labelClasses: Ref<string>;
} {
  const inputClasses = computed(() => {
    return simplifyTailwindClasses(
      defaultInputClasses,
      inputVariantClasses[props.variant.value],
      inputSizeClasses[props.size.value],
      props.error?.value ? 'border-red-400' : 'border-secondary-300',
      props.disabled.value ? disabledInputClasses : '',
    );
  });

  const labelClasses = computed(() => {
    return defaultLabelClasses;
  });

  return {
    inputClasses,
    labelClasses,
  };
}
